.card {
    border: 1px solid;
    border-top-color: rgba(228, 33, 37, 0.2);
    border-left-color: rgba(228, 33, 37, 0.2);
    border-right-color: #E42125;
    border-bottom-color: #E42125;
    /* border-image-source: linear-gradient(135deg, rgba(228, 33, 37, 0.2) 44.36%, #E42125 100%);
    border-image-slice: 1; */
    background: #E4212508;
    padding: 20px 28px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 14px;
    gap: 16px;

}

.imageContainer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card h3 {
    color: #E42125;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}