.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.sidebar-and-content-conatiner {
  margin: 0 2%;
  display: flex;
  flex-grow: 1;
}
.sidebar-container {
  width: 7%;
}
.main-contents {
  width: 93%;
}
.router-conatiner {
  margin-left: 8%;
  width: 100%;
}
