.mainContainer {
    padding: 28px 56px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 32px;
    padding-bottom: 36px;
}

.mainContainer h2 {
    color: #5A5A5A;
    font-size: 20px;
    margin-bottom: 10px;
    font-family: "Inter", serif;
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 16px;
        gap: 24px;
    }

    .mainContainer h2 {
        font-size: 18px;
        margin-bottom: 8px;
    }
}