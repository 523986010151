.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #cccccc;
}
.label {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}
