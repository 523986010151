@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.mainContainer {
    display: flex;
    width: 100%;
    position: relative;
}

.rightSection {
    width: 100%;
    margin-left: 270px;
    margin-top: 66px;
}

.iframeContainer {
    position: fixed;
    top: 66px;
    left: 270px;
    right: 0;
    bottom: 0;
    width: calc(100% - 270px);
    height: calc(100vh - 66px);
    background-color: #fff;
}

.overlay {
    position: fixed;
    top: 66px;
    left: 0;
    width: 100%;
    height: calc(100vh - 66px);
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    z-index: 900;
    cursor: pointer;
}

@media (min-width: 769px) {
    .overlay {
        display: none;
    }
}

@media (max-width: 768px) {
    .rightSection {
        margin-left: 0;
    }

    .iframeContainer {
        left: 0;
        width: 100%;
    }
}