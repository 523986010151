body {
  margin: 0;
  font-family: Inter, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  /* 1rem = 10px */
  font-size: 62.5%;
  box-sizing: border-box;
}

@media only screen and (min-width: 768px) and (max-width: 1370px) {
  html {
    font-size: 58.5%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 600px) {
  html {
    font-size: 42.5%;
  }
}
