.switchRoleButton {
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
  margin-bottom: 1.4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  margin-top: 1.5rem;
}
.applyButtonDiv {
  display: flex;
  align-items: flex-end;
  width: 40%;
  margin-left: auto;
  padding-right: 2.5rem;
  margin-top: 2.4rem;
  padding-bottom: 1.4rem;
}
.buttonSwitchDisabled {
  background-color: lightgray;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.7rem;
  /* border: 1px solid #70baff; */
  border-radius: 0.5rem;
}
.buttonswitchIM {
  background-color: #f1ecff;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #4c29b5;
  border-radius: 0.5rem;
  cursor: pointer;
}

.buttonswitchIM:hover {
  color: #fff;
  background-color: #4c29b5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.buttonswitchRM {
  background-color: #cce6ff;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #004f99;
  border-radius: 0.5rem;
  cursor: pointer;
}
.buttonswitchRM:hover {
  color: #fff;
  background-color: #004f99;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.buttonswitchNM {
  background-color: #fff2cd;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #684e01;
  border-radius: 0.5rem;
  cursor: pointer;
}
.buttonswitchNM:hover {
  color: #fff;
  background-color: #684e01;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.buttonswitchNH {
  background-color: #ffe7cc;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #9a5201;
  border-radius: 0.5rem;
  cursor: pointer;
}

.buttonswitchNH:hover {
  color: #fff;
  background-color: #9a5201;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.buttonswitchQC {
  background-color: #c5f5d9;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #0c7436;
  border-radius: 0.5rem;
  cursor: pointer;
}
.buttonswitchQC:hover {
  color: #fff;
  background-color: #0c7436;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.buttonswitchselectedIM {
  color: #fff;
  background-color: #4c29b5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #4c29b5;
  border-radius: 0.5rem;
}
.buttonswitchselectedRM {
  color: #fff;
  background-color: #004f99;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #004f99;
  border-radius: 0.5rem;
}
.buttonswitchselectedNM {
  color: #fff;
  background-color: #684e01;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #684e01;
  border-radius: 0.5rem;
}

.buttonswitchselectedNH {
  color: #fff;
  background-color: #9a5201;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #9a5201;
  border-radius: 0.5rem;
}
.buttonswitchselectedQC {
  color: #fff;
  background-color: #0c7436;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 1px solid #0c7436;
  border-radius: 0.5rem;
}
