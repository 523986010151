.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    box-shadow: 0px 4px 15.5px 0px #0000000F;
    background-color: white;
    padding: 8px 12px;
    position: fixed;
    top: 0;
    left: 270px;
    right: 0;
    z-index: 999;
}

.leftIcons {
    display: flex;
    gap: 12px;
    align-items: center;
}

.backButton {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.hamburgerButton {
    background: transparent;
    border: none;
    cursor: pointer;
    display: none;
}

.title {
    flex: 1;
    text-align: center;
    color: #E42125;
    letter-spacing: 2px;
    font-family: "Inter", serif;
    font-size: 30px;
    font-weight: 600;
}

.rightIcons {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .navbar {
        left: 0;
        padding: 8px 16px;
    }

    .hamburgerButton {
        display: flex;
        align-items: center;
    }

    .title {
        font-size: 24px;
    }
}