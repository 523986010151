.sidebar {
    width: 270px;
    background: #E42125;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 16px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
}

.closed {
    transform: translateX(-100%);
}

.profile {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: Inter;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
    height: 62px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
    list-style: none;
}

.listitems {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px;
}

.listitemslogo {
    margin-left: -8px;
    height: 56px;
}

.closeButton {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    display: none;
}

@media (max-width: 768px) {
    .closeButton {
        display: block;
    }

    .sidebar {
        z-index: 1000;
    }
}