.searchicon {
  color: #3d4756;
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: 8px;
  left: 16px;
}

.chaseLogo {
  width: 150px;
}

#sourceSystem {
  width: 18rem;
  margin-left: 20px;
}

@media (min-width: 601px) and (max-width: 900px) {
  .chaseLogo {
      width: 130px;
  }

  .searchicon {
    color: #3d4756;
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}