.fallback-main-component {
  background-color: #fefefe;
  display: flex;
  margin: auto;
  width: 43%;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
}
.error-fallabck-exclamation-image {
  height: 50px;
  margin-bottom: 1.5rem;
}
