.sideBar-icon{
  text-decoration: none;
}
.sideBar-icon-tour {
    background: white;
    padding: 12px;
    border-radius: 16px;
    text-decoration: none;
    min-width: 92px;
    transition: all 0.2s;
    box-shadow: 0px 2px 10px 0px #FFFFFFB2;
    border-radius: 20px;
    border: 1px solid #9F9F9F;
}

