.cardsRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
}

@media (max-width: 1024px) {
    .cardsRow {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .cardsRow {
        grid-template-columns: 1fr;
    }
}